<template>
  <v-main>
    <v-container>
      <Loader v-if="showLoader" />
      <v-col
        v-else
        class="px-0 py-0 bookingTicketText"
        style="margin-bottom: 70px"
      >
        <page-step
          style="margin: 20px 0px"
          :pages="[
            {
              id: 1,
              name: `${detailOrder?.departure?.translations?.name}-${detailOrder?.destination?.translations?.name}`,
              link: `/search/${detailOrder?.departure?.id}/${detailOrder?.destination?.id}?departureDate=${trip.date}&qty=${trip.qty}`,
            },
            {
              id: 2,
              name: 'Купівля',
              link: '/order',
            },
          ]"
        />
        <v-row no-gutters style="margin-top: 20px">
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <!-- SESION TIME CARD -->
            <session-time-card style="margin-top: 20px" />
            <!-- DEPARTURE INFO CARD -->
            <div class="bookingTicketCardBackground" style="margin-top: 20px">
              <span class="bookingTicketTitle"
                >{{ "journey_to_label" | localize }}
                <span style="color: #144fa9">Краків</span></span
              >
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center">
                <div class="box" style="margin-right: 8px">
                  {{ "departure_label" | localize }}
                </div>
                <span
                  style="font-size: 14px; color: #4b5262; font-weight: 600"
                  >{{
                    new Date(
                      detailOrder?.schedule?.departed_at
                    ).toLocaleDateString("uk-UA", {
                      day: "2-digit",
                      month: "short",
                    })
                  }}</span
                >
              </v-row>
              <booking-ticket-route-detail
                :detailOrder="detailOrder"
                style="margin-top: 12px"
              />
              <v-row no-gutters align="center" style="margin-top: 12px">
                <div class="box" style="background: #172b69; margin-right: 8px">
                  {{ "arrival_label" | localize }}
                </div>
                <v-row no-gutters align="center">
                  <span
                    style="font-size: 14px; color: #4b5262; font-weight: 600"
                    >{{
                      new Date(
                        detailOrder?.schedule?.arrival_at
                      ).toLocaleDateString("uk-UA", {
                        day: "2-digit",
                        month: "short",
                      })
                    }}.</span
                  >
                  <div
                    style="
                      width: 4px;
                      height: 4px;
                      background: #4b5262;
                      border-radius: 50%;
                      margin: 0px 8px;
                    "
                  ></div>
                  <span
                    style="font-size: 14px; color: #4b5262; font-weight: 600"
                    >{{ "duration_label" | localize }}
                    {{
                      setDifferentTime(
                        addMinutesToDate(
                          detailOrder?.schedule?.departed_at,
                          detailOrder?.schedule?.route?.cities?.find(
                            (city) =>
                              city.station.city_uuid ==
                              detailOrder?.departure.id
                          )?.time_from_start
                        ),
                        addMinutesToDate(
                          detailOrder?.schedule?.departed_at,
                          detailOrder?.schedule?.route?.cities?.find(
                            (city) =>
                              city.station.city_uuid ==
                              detailOrder?.destination.id
                          )?.time_from_start
                        )
                      )
                    }}
                  </span>
                </v-row>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="8"
            lg="8"
            md="8"
            sm="12"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 20px'"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <!-- AUTH BANNER -->
            <div
              class="bookingTicketCardBackground"
              v-if="!loggedUserPTrans"
              style="margin-bottom: 40px"
            >
              <v-row no-gutters align="center">
                <v-col
                  style="text-align: left; padding-right: 20px"
                  class="px-0 py-0"
                >
                  <p class="bookingTicketTitle" style="margin: 0px 0px 8px 0px">
                    {{ "fast_sign_in_title" | localize }}
                  </p>
                  <p style="margin: 0px; color: #4b5262">
                    {{ "fast_sign_in_subtitle" | localize }}
                  </p>
                </v-col>
                <img
                  src="@/assets/img/bookingTicketImg.png"
                  width="200px"
                  height="130px"
                  alt="booking ticket"
                />
              </v-row>
              <v-row no-gutters align="center">
                <v-col class="py-0" style="padding-right: 10px">
                  <button class="socialBtn">
                    <img
                      src="@/assets/img/socialsIcon/googleIcon.svg"
                      alt="google"
                      style="margin-right: 10px"
                    />
                    {{ "continue_with_google_label" | localize }}
                  </button>
                </v-col>
                <v-col class="py-0" style="padding-left: 10px">
                  <button class="socialBtn">
                    <img
                      src="@/assets/img/socialsIcon/facebookIcon.svg"
                      alt="facebook"
                      style="margin-right: 10px"
                    />
                    {{ "continue_with_facebook_label" | localize }}
                  </button>
                </v-col>
              </v-row>
            </div>
            <!-- DOCUMENT BANNER -->
            <info-banner style="margin-top: 0px">
              {{ "booking_ticket_warning" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "rules_border_crossing" | localize }}</span
              >
              {{ "and_label" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "transportation_rules" | localize }}</span
              >
            </info-banner>
            <!-- USER INFO CARD -->
            <passanger-info-form
              style="margin-top: 40px"
              v-for="(passanger, index) in detailOrder.tickets"
              :key="index"
              :index="index + 1"
              :touch="touch"
              :passanger="passanger"
              :passangerTypes="passangerTypes"
            />

            <!-- ADDITIONAL INFO CARD -->
            <div class="bookingTicketCardBackground" style="margin-top: 40px">
              <span class="bookingTicketTitle">{{
                "additional_info_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <span style="font-size: 16px; font-weight: 600">{{
                "baggage_label" | localize
              }}</span>
              <p style="margin-top: 8px; color: #4b5262">
                {{ "baggage_warning" | localize }}
              </p>
            </div>
            <!-- PROMOCODE CARD -->
            <div
              class="bookingTicketCardBackground"
              style="margin-top: 40px"
              v-if="detailOrder.promocode == null && loggedUserPTrans"
            >
              <span class="bookingTicketTitle">{{
                "promocode_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters>
                <v-col class="px-0 py-0" cols="8">
                  <v-text-field
                    outlined
                    dense
                    :label="$options.filters.localize('promocode_label')"
                    :placeholder="$options.filters.localize('type_here')"
                    style="border-radius: 10px"
                    height="48px"
                    v-model="promocode"
                    :error-messages="promocodeError"
                    @input="promocodeError = []"
                  />
                </v-col>
                <v-col cols="4" style="padding-left: 20px">
                  <submit-button
                    style="height: 48px !important"
                    @click="applyPromocode"
                    >{{ "apply_btn" | localize }}</submit-button
                  >
                </v-col>
              </v-row>
            </div>
            <!-- BONUS CARD -->
            <div
              class="bookingTicketCardBackground"
              style="margin-top: 40px"
              v-if="userBonusBalance > 0"
            >
              <v-row no-gutters justify="space-between">
                <v-col class="px-0 py-0">
                  <p class="bookingTicketTitle" style="margin-bottom: 8px">
                    {{ "pay_to_bonuses_label" | localize }}
                  </p>
                </v-col>
                <v-col class="px-0 py-0" style="text-align: end">
                  <span style="font-size: 16px; font-weight: 400"
                    >{{ "balance_label" | localize }}:
                    <span style="color: #144fa9; font-weight: 600">{{
                      userBonusBalance.toFixed(2)
                    }}</span></span
                  >
                </v-col>
              </v-row>
              <div class="sectionLine" />
              <v-row no-gutters justify="space-between">
                <v-col class="px-0 py-0">
                  <span class="bookingTicketText" style="color: #4b5262">{{
                    "bonuses_description" | localize
                  }}</span>
                </v-col>
                <v-col class="px-0 py-0" style="text-align: end">
                  <v-row no-gutters justify="end">
                    <v-switch
                      inset
                      style="margin: 0px 0px 0px 0px; width: 44px"
                      v-model="isUseBonus"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <info-banner style="margin-top: 40px">
              {{ "booking_ticket_saved_data_warning" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "click_here_label" | localize }}.</span
              >
            </info-banner>
            <v-row no-gutters align="center" style="margin-top: 20px">
              <v-checkbox
                color="#144FA9"
                style="margin-right: 8px"
                v-model="isCheck"
              />
              <v-col class="px-0 py-0">
                <span style="color: #4b5262; font-size: 14px; font-weight: 400"
                  >{{ "user_confirmed_label" | localize }}
                  <span class="pointer" style="text-decoration: underline">
                    {{ "public_offer_contract_label" | localize }}</span
                  >,
                  <span class="pointer" style="text-decoration: underline"
                    >{{
                      "contract_carriage_passanger_and_baggage_label" | localize
                    }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <submit-button
              style="height: 48px !important; margin-top: 20px"
              :disabled="!isCheck"
              @click="checkValidation"
              >{{ "go_to_payment_btn" | localize }}</submit-button
            >
          </v-col>
          <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown">
            <!-- DEPARTURE INFO CARD -->
            <div class="bookingTicketCardBackground">
              <span class="bookingTicketTitle"
                >{{ "journey_to_label" | localize }}
                <span style="color: #144fa9">{{
                  detailOrder?.destination?.translations?.name
                }}</span></span
              >
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center">
                <div class="box" style="margin-right: 8px">
                  {{ "departure_label" | localize }}
                </div>
                <span
                  style="font-size: 14px; color: #4b5262; font-weight: 600"
                  >{{
                    new Date(
                      detailOrder?.schedule?.departed_at
                    ).toLocaleDateString("uk-UA", {
                      day: "2-digit",
                      month: "short",
                    })
                  }}</span
                >
              </v-row>
              <booking-ticket-route-detail
                :detailOrder="detailOrder"
                style="margin-top: 12px"
              />
              <v-row no-gutters align="center" style="margin-top: 12px">
                <div class="box" style="background: #172b69; margin-right: 8px">
                  {{ "arrival_label" | localize }}
                </div>
                <v-row no-gutters align="center">
                  <span
                    style="font-size: 14px; color: #4b5262; font-weight: 600"
                    >{{
                      new Date(
                        detailOrder?.schedule?.arrival_at
                      ).toLocaleDateString("uk-UA", {
                        day: "2-digit",
                        month: "short",
                      })
                    }}.</span
                  >
                  <div
                    style="
                      width: 4px;
                      height: 4px;
                      background: #4b5262;
                      border-radius: 50%;
                      margin: 0px 8px;
                    "
                  ></div>
                  <span
                    style="font-size: 14px; color: #4b5262; font-weight: 600"
                    >{{ "duration_label" | localize }}
                    {{
                      setDifferentTime(
                        addMinutesToDate(
                          detailOrder?.schedule?.departed_at,
                          detailOrder?.schedule?.route?.cities?.find(
                            (city) =>
                              city.station.city_uuid ==
                              detailOrder?.departure.id
                          )?.time_from_start
                        ),
                        addMinutesToDate(
                          detailOrder?.schedule?.departed_at,
                          detailOrder?.schedule?.route?.cities?.find(
                            (city) =>
                              city.station.city_uuid ==
                              detailOrder?.destination.id
                          )?.time_from_start
                        )
                      )
                    }}
                  </span>
                </v-row>
              </v-row>
            </div>
            <!-- PRICE INFO CARD -->
            <div class="bookingTicketCardBackground" style="margin-top: 20px">
              <span class="bookingTicketTitle">{{
                "price_information_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center" style="margin-bottom: 20px">
                <div class="userIcon" style="margin-right: 8px" />
                <span style="color: #4b5262; font-size: 16px; font-weight: 600"
                  >{{ detailOrder.seats_count }}
                  {{ getPassengerText(detailOrder.seats_count) }}</span
                >
              </v-row>
              <v-row
                no-gutters
                justify="space-between"
                v-for="(passanger, key, index) in passangerTypesForUser"
                :key="(passanger.id, index)"
              >
                <v-col class="px-0 py-0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    v-for="item in passanger"
                    :key="item.id"
                    :style="'margin-top: 12px;'"
                  >
                    <span style="font-size: 14px; font-weight: 400"
                      >{{ item.length }} {{ item?.[0]?.title }}</span
                    >
                    <span
                      style="font-size: 14px; color: #4b5262; font-weight: 600"
                      >{{ parseFloat(key) * item.length }} грн</span
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
                justify="space-between"
                :style="'margin-top: 12px;'"
                v-if="discountAmount > 0"
              >
                <span style="font-size: 14px; font-weight: 400">Знижка</span>
                <span style="font-size: 14px; color: #4b5262; font-weight: 600"
                  >-{{ discountAmount.toFixed(2) }} грн</span
                >
              </v-row>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center" justify="space-between">
                <span style="font-size: 20px; font-weight: 700">{{
                  "to_be_paid" | localize
                }}</span>
                <span style="font-size: 24px; color: #144fa9; font-weight: 600"
                  >{{ orderSum.toFixed(2) }} грн</span
                >
              </v-row>
              <div
                style="
                  border-radius: 10px;
                  background: #f5f5f5;
                  padding: 4px 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                "
              >
                <img
                  src="@/assets/img/iconsSvg/bonusIcon.svg"
                  alt="bonus icon"
                  style="margin-right: 8px"
                />
                <span
                  style="font-size: 14px; font-style: normal; font-weight: 400"
                  >+{{ (orderSum * bonus_amount).toFixed(2) }}
                  {{ "quantity_bonus_label" | localize }}</span
                >
              </div>
            </div>
            <!-- SESION TIME CARD -->
            <session-time-card style="margin-top: 20px" />
          </v-col>
          <v-col cols="12" v-else>
            <!-- DOCUMENT BANNER -->
            <info-banner style="margin-top: 40px">
              {{ "booking_ticket_warning" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "rules_border_crossing" | localize }}</span
              >
              {{ "and_label" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "transportation_rules" | localize }}</span
              >
            </info-banner>
            <!-- USER INFO CARD -->
            <passanger-info-form
              style="margin-top: 40px"
              v-for="(passanger, index) in detailOrder.tickets"
              :key="index"
              :index="index + 1"
              :touch="touch"
              :passanger="passanger"
              :passangerTypes="passangerTypes"
            />
            <!-- ADDITIONAL INFO CARD -->
            <div class="bookingTicketCardBackground" style="margin-top: 40px">
              <span class="bookingTicketTitle">{{
                "additional_info_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <span style="font-size: 16px; font-weight: 600">{{
                "baggage_label" | localize
              }}</span>
              <p style="margin-top: 8px; color: #4b5262">
                {{ "baggage_warning" | localize }}
              </p>
            </div>
            <info-banner style="margin-top: 20px">
              {{ "booking_ticket_saved_data_warning" | localize }}
              <span
                style="font-weight: 500; text-decoration-line: underline"
                class="pointer"
                >{{ "click_here_label" | localize }}.</span
              >
            </info-banner>
            <!-- MOBILE PROMOCODE CARD -->
            <div
              class="bookingTicketCardBackground"
              style="margin-top: 40px"
              v-if="
                detailOrder.promocode == null &&
                loggedUserPTrans &&
                $vuetify.breakpoint.smAndDown
              "
            >
              <span class="bookingTicketTitle">{{
                "promocode_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters>
                <v-col class="px-0 py-0" cols="7">
                  <v-text-field
                    outlined
                    dense
                    :label="$options.filters.localize('promocode_label')"
                    :placeholder="$options.filters.localize('type_here')"
                    style="border-radius: 10px"
                    height="48px"
                    v-model="promocode"
                    :error-messages="promocodeError"
                    @input="promocodeError = []"
                  />
                </v-col>
                <v-col cols="5" style="padding-left: 20px">
                  <submit-button
                    style="height: 48px !important"
                    @click="applyPromocode"
                    >{{ "apply_btn" | localize }}</submit-button
                  >
                </v-col>
              </v-row>
            </div>
            <!-- MOBILE BONUS CARD -->
            <div
              class="bookingTicketCardBackground"
              style="margin-top: 40px"
              v-if="userBonusBalance > 0 && $vuetify.breakpoint.smAndDown"
            >
              <v-row no-gutters justify="space-between">
                <v-col class="px-0 py-0">
                  <p class="bookingTicketTitle" style="margin-bottom: 8px">
                    {{ "pay_to_bonuses_label" | localize }}
                  </p>
                </v-col>
                <v-col class="px-0 py-0" style="text-align: end">
                  <span style="font-size: 16px; font-weight: 400"
                    >{{ "balance_label" | localize }}:
                    <span style="color: #144fa9; font-weight: 600">{{
                      userBonusBalance.toFixed(2)
                    }}</span></span
                  >
                </v-col>
              </v-row>
              <div class="sectionLine" />
              <v-row no-gutters justify="space-between">
                <v-col cols="10" class="px-0 py-0">
                  <span class="bookingTicketText" style="color: #4b5262">{{
                    "bonuses_description" | localize
                  }}</span>
                </v-col>
                <v-col class="px-0 py-0" style="text-align: end">
                  <v-row no-gutters justify="end">
                    <v-switch
                      inset
                      style="margin: 0px 0px 0px 0px; width: 44px"
                      v-model="isUseBonus"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <!-- PRICE INFO CARD -->
            <div
              class="bookingTicketCardBackground"
              style="margin-top: 40px"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              <span class="bookingTicketTitle">{{
                "price_information_label" | localize
              }}</span>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center" style="margin-bottom: 20px">
                <div class="userIcon" style="margin-right: 8px" />
                <span style="color: #4b5262; font-size: 16px; font-weight: 600"
                  >{{ detailOrder.seats_count }}
                  {{ getPassengerText(detailOrder.seats_count) }}</span
                >
              </v-row>
              <v-row
                no-gutters
                justify="space-between"
                v-for="(passanger, key, index) in passangerTypesForUser"
                :key="(passanger.id, index)"
                :style="index > 1 ? 'margin-top: 12px;' : ''"
              >
                <v-col class="px-0 py-0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-between"
                    v-for="item in passanger"
                    :key="item.id"
                    :style="'margin-top: 12px;'"
                  >
                    <span style="font-size: 14px; font-weight: 400"
                      >{{ item.length }} {{ item?.[0]?.title }}</span
                    >
                    <span
                      style="font-size: 14px; color: #4b5262; font-weight: 600"
                      >{{ parseFloat(key) * item.length }} грн</span
                    >
                  </v-row></v-col
                >
              </v-row>
              <div class="sectionLine" style="margin: 20px 0px" />
              <v-row no-gutters align="center" justify="space-between">
                <span style="font-size: 20px; font-weight: 700">{{
                  "to_be_paid" | localize
                }}</span>
                <span style="font-size: 24px; color: #144fa9; font-weight: 600">
                  {{ orderSum.toFixed(2) }}грн</span
                >
              </v-row>
              <div
                style="
                  border-radius: 10px;
                  background: #f5f5f5;
                  padding: 4px 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                "
              >
                <img
                  src="@/assets/img/iconsSvg/bonusIcon.svg"
                  alt="bonus icon"
                  style="margin-right: 8px"
                />
                <span
                  style="font-size: 14px; font-style: normal; font-weight: 400"
                  >+{{ (orderSum * bonus_amount).toFixed(2) }}
                  {{ "quantity_bonus_label" | localize }}</span
                >
              </div>
            </div>
            <v-row no-gutters align="center" style="margin-top: 20px">
              <v-checkbox
                color="#144FA9"
                style="margin-right: 8px"
                v-model="isCheck"
              />
              <v-col class="px-0 py-0">
                <span style="color: #4b5262; font-size: 14px; font-weight: 400"
                  >{{ "user_confirmed_label" | localize }}
                  <span class="pointer" style="text-decoration: underline">
                    {{ "public_offer_contract_label" | localize }}</span
                  >,
                  <span class="pointer" style="text-decoration: underline"
                    >{{
                      "contract_carriage_passanger_and_baggage_label" | localize
                    }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <submit-button
              style="height: 48px !important; margin-top: 20px"
              :disabled="!isCheck"
              @click="checkValidation"
              >{{ "go_to_payment_btn" | localize }}</submit-button
            >
          </v-col>
        </v-row>
      </v-col>
      <booking-ticket-payment-modal
        v-if="showPaymentModal"
        :visible="showPaymentModal"
        :liqPayBtn="liqpay_btn"
        :totalPrice="totalPrice"
        :passangerTypesForUser="passangerTypesForUser"
        :detailOrder="detailOrder"
        :discountAmount="discountAmount"
        @close="showPaymentModal = false"
      />
    </v-container>
  </v-main>
</template>

<script>
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import SessionTimeCard from "../../UI/Cards/sessionTimeCard.vue";
import InfoBanner from "../../UI/infoBanner.vue";
import PageStep from "../../UI/pageStep.vue";
import BookingTicketRouteDetail from "./bookingTicketRouteDetail.vue";
import PassangerInfoForm from "./passangerInfoForm.vue";
import bookingTicketService from "../../../requests/User/bookingTicketService";
import requestFormData from "../../../requests/requestFormData";
import { mapGetters } from "vuex";
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
import Loader from "../../UI/Loader.vue";
import BookingTicketPaymentModal from "./bookingTicketPaymentModal.vue";
import profileService from "../../../requests/User/profileService";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  components: {
    PageStep,
    InfoBanner,
    PassangerInfoForm,
    SubmitButton,
    SessionTimeCard,
    BookingTicketRouteDetail,
    Loader,
    BookingTicketPaymentModal,
  },
  mixins: [diffrentTimeMixin, validationMixin],
  data: () => ({
    isCheck: false,
    invalidData: true,
    touch: false,
    showLoader: true,
    isOrderCreated: false,
    userValidData: false,
    showPaymentModal: false,
    isUseBonus: false,
    orderSum: 0,
    totalPrice: 0,
    discountAmount: 0,
    userBonusBalance: 0,
    bonus_amount: parseFloat(localStorage.getItem("bonus_amount")) / 100,
    promocode: "",
    liqpay_btn: "",
    trip: {},
    detailOrder: {},
    passangerTypesForUser: [],
    passangerTypes: [],
    promocodeError: [],
    seasonDiscount: [],
  }),
  validations: {
    detailOrder: {
      tickets: {
        $each: {
          first_name: {
            required,
          },
          last_name: {
            required,
          },
          phone_number: {
            required,
            minLength: minLength(11),
          },
          email: {
            required,
            email,
          },
        },
      },
    },
  },
  mounted() {
    this.trip = JSON.parse(sessionStorage.getItem("recent_trip_search"));
    if (
      sessionStorage.getItem("order_uuid") &&
      sessionStorage.getItem("trip_id") == this.trip.trip_id
    ) {
      this.getOrderByUuid();
    } else {
      this.bookingTicket();
    }
    if (this.loggedUserPTrans !== null) {
      this.getUserBalance();
    }
  },
  methods: {
    checkValidation() {
      if (!this.$v.$invalid) {
        this.createOrder();
      } else {
        this.touch = true;
      }
    },
    groupPassanger() {
      let group = [];
      this.discountAmount = 0;
      this.detailOrder.tickets.forEach((passanger) => {
        group.push({
          ...passanger,
          price:
            passanger.type == ""
              ? this.getPassangerPrice(passanger)
              : passanger.price,
          type: passanger.type || "",
          title:
            this.passangerTypes.find((type) => type.id == passanger.type)
              ?.translations?.title || "Повний",
          discount_uuid: passanger.type || "",
        });
      });
      this.passangerTypesForUser = this.groupByPrice(group);
      let initialValue = 0;
      this.orderSum = group.reduce(
        (accumulator, item) => accumulator + item.price,
        initialValue
      );
      if (this.detailOrder.promocode && this.detailOrder.promocode.amount) {
        this.discountAmount =
          this.orderSum * (this.detailOrder.promocode.amount / 100);
        this.orderSum = this.orderSum - this.discountAmount;
      }
      if (
        this.detailOrder.expense_bonus &&
        this.detailOrder.expense_bonus.amount
      ) {
        this.discountAmount += this.detailOrder.expense_bonus.amount;
        this.orderSum =
          this.discountAmount > this.orderSum
            ? 0
            : this.orderSum - this.detailOrder.expense_bonus.amount;
      }
    },
    groupByPrice(items) {
      const groupedItems = {};

      items.forEach((item) => {
        const price = item.price;
        const title = item.title;

        // Перевіряємо, чи ключ з цією ціною вже існує
        if (!groupedItems[price]) {
          groupedItems[price] = {};
        }

        // Перевіряємо, чи ключ з цим заголовком вже існує
        if (!groupedItems[price][title]) {
          groupedItems[price][title] = [];
        }

        // Додаємо поточний об'єкт до масиву об'єктів з такою ж ціною та заголовком
        groupedItems[price][title].push(item);
      });

      return groupedItems;
    },
    getPassangerPrice(passanger) {
      if (this.seasonDiscount && this.seasonDiscount.amount) {
        let discountAmount =
          passanger.price * (this.seasonDiscount.amount / 100);
        this.discountAmount = +discountAmount;
        return passanger.price - discountAmount;
      } else {
        return passanger.price;
      }
    },
    async getUserBalance() {
      await profileService.getBalance().then((res) => {
        if (res.status == "Success") {
          this.userBonusBalance = res.data.balance;
        }
      });
    },
    async getOrderByUuid() {
      this.quantityPassangers = parseInt(this.trip.qty);
      if (this.loggedUserPTrans !== null) {
        await bookingTicketService
          .getOrderByUuid(sessionStorage.getItem("order_uuid"))
          .then((res) => {
            if (res.status == "Success") {
              res.data.tickets = res.data.tickets.map((ticket) => ({
                ...ticket,
                type: "",
              }));

              this.detailOrder = res.data;
              this.seasonDiscount = res?.data?.available_discounts?.filter(
                (discount) => discount.discount_type == "Season"
              )?.[0];
              this.passangerTypes = res.data.schedule.social_discount;
              this.passangerTypes.unshift({
                translations: {
                  title: "Повний",
                },
                id: "",
              });
              // this.promocode = res.data?.promocode?.code;
              this.trip = JSON.parse(
                sessionStorage.getItem("recent_trip_search")
              );
              if (res.data.seats_count !== parseInt(this.trip.qty)) {
                setTimeout(() => {
                  this.showLoader = true;
                }, 100);
                this.setSeats();
              }
              //this.setDiscount();
              this.groupPassanger();
            }
            res.data.expense_bonus !== null
              ? ((this.isUseBonus = true),
                (this.userBonusBalance = res.data.expense_bonus.amount))
              : (this.isUseBonus = false);

            this.key++;
            this.showLoader = false;
          });
      } else {
        await bookingTicketService
          .getOrderByUuIdWithoutAuth(sessionStorage.getItem("order_uuid"))
          .then((res) => {
            if (res.status == "Success") {
              res.data.tickets = res.data.tickets.map((ticket) => ({
                ...ticket,
                type: "",
              }));

              this.detailOrder = res.data;
              this.seasonDiscount = res?.data?.available_discounts?.filter(
                (discount) => discount.discount_type == "Season"
              )?.[0];
              this.passangerTypes = res.data.schedule.social_discount;
              this.passangerTypes.unshift({
                translations: {
                  title: "Повний",
                },
                id: "",
              });
              // this.promocode = res.data?.promocode?.code;
              this.trip = JSON.parse(
                sessionStorage.getItem("recent_trip_search")
              );
              if (res.data.seats_count !== parseInt(this.trip.qty)) {
                setTimeout(() => {
                  this.showLoader = true;
                }, 100);
                this.setSeats();
              }
              //this.setDiscount();
              this.groupPassanger();
            }
            this.key++;
            this.showLoader = false;
          });
      }
    },
    async bookingTicket() {
      this.quantityPassangers = parseInt(this.trip.qty);
      let form = new FormData();
      form.append("departure_city", this.trip.departureCity);
      form.append("arrival_city", this.trip.arrivalCity);
      form.append("ticket_count", this.trip.qty);
      form.append("schedule_uuid", this.trip.trip_id);
      if (this.loggedUserPTrans !== null) {
        await bookingTicketService
          .bookingTicket(form)
          .then((res) => {
            if (res.status == "Success") {
              this.order_uuid = res.data.id;
              sessionStorage.setItem("order_uuid", this.order_uuid);
              sessionStorage.setItem("trip_id", this.trip.trip_id);
              this.getOrderByUuid();
            }
          })
          .catch((res) => {
            if (
              res?.response?.data?.data?.seats_available ==
              "Not enough free places"
            ) {
              this.showLoader = false;
              this.showErrorModal = true;
            }
          });
      } else {
        await bookingTicketService
          .bookingTicketWithoutAuth(form)
          .then((res) => {
            if (res.status == "Success") {
              this.order_uuid = res.data.id;
              sessionStorage.setItem("order_uuid", this.order_uuid);
              sessionStorage.setItem("trip_id", this.trip.trip_id);
              this.getOrderByUuid();
            }
          })
          .catch((res) => {
            if (
              res?.response?.data?.data?.seats_available ==
              "Not enough free places"
            ) {
              this.showLoader = false;
              this.showErrorModal = true;
            }
          });
      }
    },
    async createOrder() {
      this.touch = true;
      if (!this.$v.$invalid) {
        let order = [];
        order["schedule_uuid"] = this.trip.trip_id;
        let discountUuids = [];
        let tickets = [];
        tickets = this.detailOrder.tickets.map((ticket, index) => {
          discountUuids = [];
          ticket.discounts.forEach((discount) => {
            if (discount.type == "Social") {
              discountUuids.push(discount.discount_uuid);
            }
          });
          if (
            ticket.type !== "" &&
            ticket.type !== null &&
            ticket.type !== undefined
          ) {
            discountUuids.push(ticket.type);
          }
          ticket = {
            first_name: ticket.first_name,
            last_name: ticket.last_name,
            email: ticket.email,
            phone_number: ticket?.phone_number?.replace(/\D+/g, ""),
            discount: discountUuids?.[0] || "",
            id: this.detailOrder?.tickets[index]?.id,
          };
          return ticket;
        });
        tickets.forEach((ticket) => {
          if (ticket.discount === "") {
            delete ticket.discount;
          }
        });
        let data = {
          departure_city: this.trip.departureCity,
          arrival_city: this.trip.arrivalCity,
          ticket_count: this.trip.qty,
          order: order,
          ticket: tickets,
        };
        let form = requestFormData.jsonToFormData(data);
        if (this.loggedUserPTrans !== null) {
          await bookingTicketService
            .createOrder(form, sessionStorage.getItem("order_uuid"))
            .then(async (res) => {
              if (res.status == "Success") {
                if (this.orderSum > 0) {
                  this.liqpay_btn = res.data.liqpay.replace(
                    'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
                    'src="https://i.ibb.co/SP1cZmj/Group-82.png"'
                  );
                  setTimeout(() => {
                    this.showPaymentModal = true;
                  }, 100);
                  this.totalPrice = res.data.order.final_price;
                  localStorage.removeItem("startDate");
                  sessionStorage.removeItem("order_uuid");
                  sessionStorage.removeItem("trip_id");
                } else {
                  await bookingTicketService
                    .confirmPay(sessionStorage.getItem("order_uuid"))
                    .then((res) => {
                      if (res.status == "Success") {
                        this.$router.push({
                          name: "success_page",
                          params: {
                            order_id: sessionStorage.getItem("order_uuid"),
                          },
                        });
                        localStorage.removeItem("startDate");
                        sessionStorage.removeItem("order_uuid");
                        sessionStorage.removeItem("trip_id");
                      }
                    });
                }
              } else if (res.status == "failed") {
                alert("Закінчились місця");
              }
            });
        } else {
          await bookingTicketService
            .createOrderWithoutAuth(form, sessionStorage.getItem("order_uuid"))
            .then((res) => {
              if (res.status == "Success") {
                this.liqpay_btn = res.data.liqpay.replace(
                  'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
                  'src="https://i.ibb.co/SP1cZmj/Group-82.png"'
                );
                setTimeout(() => {
                  this.showPaymentModal = true;
                }, 100);
                this.totalPrice = res.data.order.final_price;
                localStorage.removeItem("startDate");
                sessionStorage.removeItem("order_uuid");
                sessionStorage.removeItem("trip_id");
              } else if (res.status == "failed") {
                alert("Закінчились місця");
              }
            });
        }
      }
    },
    async setSeats() {
      let ticket = [];
      for (let index = 0; index < this.trip.qty; index++) {
        // if (
        //   this.detailOrder.tickets[index].ticket_discount.findIndex(
        //     (ticket) => ticket.discount_type == "Ticket_count"
        //   ) !== -1
        // ) {
        //   ticket.push({
        //     id: this.detailOrder.tickets[index].id,
        //     uuid: this.detailOrder.tickets[index].uuid,
        //     discount: this.detailOrder.tickets[index].ticket_discount.find(
        //       (ticket) => ticket.discount_type == "Ticket_count"
        //     ),
        //   });
        // } else {
        if (index < this.detailOrder.tickets.length) {
          ticket.push({
            id: this.detailOrder?.tickets?.[index]?.id,
            discount: [],
          });
        } else {
          ticket.push({ discount: [] });
        }
        //}
      }
      let data = {
        ticket_count: this.trip.qty,
        ticket: ticket,
      };
      const form = requestFormData.jsonToFormData(data);
      if (this.loggedUserPTrans !== null) {
        await bookingTicketService
          .changeSeatsQuantity(this.detailOrder.id, form)
          .then(() => {
            this.getOrderByUuid();
          })
          .catch(() => {
            this.showLoader = false;
          });
      } else {
        await bookingTicketService
          .changeSeatsQuantityWithoutAuth(this.detailOrder.id, form)
          .then(() => {
            this.getOrderByUuid();
          })
          .catch(() => {
            this.showLoader = false;
          });
      }
    },
    async applyPromocode() {
      let form = new FormData();
      form.append("promocode", this.promocode);
      if (this.loggedUserPTrans !== null) {
        await bookingTicketService
          .applyPromocode(this.detailOrder.id, form)
          .then((res) => {
            if (res.status == "Success") {
              const oldTicket = this.detailOrder.tickets;
              this.detailOrder = res.data;
              this.detailOrder.tickets = oldTicket;
            }
          })
          .catch(() => {
            this.promocodeError = "Такого промокоду не існує";
          });
      } else {
        await bookingTicketService
          .applyPromocodeWithoutAuth(this.detailOrder.id, form)
          .then((res) => {
            if (res.status == "Success") {
              const oldTicket = this.detailOrder.tickets;
              this.detailOrder = res.data;
              this.detailOrder.tickets = oldTicket;
            }
          })
          .catch(() => {
            this.promocodeError = "Такого промокоду не існує";
          });
      }
    },
    async checkUsBonus() {
      if (this.isUseBonus) {
        await bookingTicketService
          .useBonuses(this.detailOrder.id)
          .then((res) => {
            if (res.status == "Success") {
              const oldTicket = this.detailOrder.tickets;
              this.detailOrder = res.data;
              this.detailOrder.tickets = oldTicket;
            }
          });
      } else {
        await bookingTicketService
          .declineBonuses(this.detailOrder.id)
          .then((res) => {
            if (res.status == "Success") {
              const oldTicket = this.detailOrder.tickets;
              this.detailOrder = res.data;
              this.detailOrder.tickets = oldTicket;
            }
          });
      }
      this.groupPassanger();
    },
    async assignOrder() {
      await bookingTicketService.asignOrder(this.detailOrder.uuid);
    },
    getPassengerText(count) {
      if (count === 1) {
        return this.$options.filters.localize("passanger_label")?.toLowerCase();
      } else if (count > 1 && count < 5) {
        return this.$options.filters
          .localize("passangers_label")
          ?.toLowerCase();
      } else {
        return this.$options.filters
          .localize("to_many_passanger_label")
          .toLowerCase();
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
  watch: {
    touch: {
      handler() {
        setTimeout(() => {
          this.touch = false;
        }, 500);
      },
    },
    "detailOrder.tickets": {
      deep: true,
      handler() {
        this.groupPassanger();
      },
    },
    isUseBonus: {
      handler() {
        this.checkUsBonus();
      },
    },
    loggedUserPTrans: {
      handler() {
        if (this.loggedUserPTrans !== null) {
          this.assignOrder();
        }
      },
    },
  },
};
</script>

<style scoped>
.bookingTicketText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 400;
}
.bookingTicketTitle {
  font-size: 20px;
  font-weight: 700;
}
.bookingTicketCardBackground {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.socialBtn {
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #fff;
  padding: 12px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;
  width: 100%;
}
.box {
  border-radius: 10px;
  background: #144fa9;
  width: max-content;
  padding: 4px 8px;
  color: #fafafa;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>